<template>
  <a-modal :title="'用户管理'" :width="640" :visible="visible" @ok="ok"  @cancel="cancel" >
    <a-form :form="form" :label-col="{span:6}" :wrapper-col="{span:16}">
			<a-form-item label="隐藏字段" style="display:none;">
			</a-form-item>
      <a-form-item label="登录账号">
        <a-input v-decorator="[ 'username', { rules: [{ required: true, message: '' }] }]" />
      </a-form-item>
			<a-form-item label="登录密码">
			  <a-input v-decorator="[ 'password']" />
			</a-form-item>
			<a-form-item label="用户名称">
			  <a-input v-decorator="[ 'name', { rules: [{ required: true, message: '' }] }]" />
			</a-form-item>
			<a-form-item label="权限列表" v-if="id>1">
			  <a-tree v-model="auth" @select="onSelect" checkable :tree-data="this.$store.state.auth" :replaceFields="{children:'child',title:'name',key:'id'}"/>
			</a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import pick from "lodash.pick"

// 表单字段
const fields = ["username", "password","name","auth"];

export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
			auth:[],
			id:0
    };
  },
  created() {
    fields.forEach((v) => this.form.getFieldDecorator(v));
  },
  methods: {
    init(e) {
			let self = this
			e.password = ''
			this.form.setFieldsValue(pick(e, fields))
			this.auth = e.auth
			this.id = e.id
      this.visible = true
    },
    cancel() {
      this.visible = false;
			this.form.resetFields()
    },
    ok() {
      this.form.validateFields((errors, values) => {
        if (!errors){
					values.auth = this.auth
					this.$emit("ok", values)
					this.form.resetFields()
				} 		
      })
    },
		onSelect(e){
			console.log(e)
		}
  },
}
</script>
