<template>
  <div class="qiqb-table">	
    <div class="qiqb-table-item">
      <div class="table-operator">
        <div class="table-operator-title">{{ name }}</div>
        <a-button type="primary" @click="handleEdit({ id: 0 })">新建</a-button>
      </div>
      <div class="table-item">
        <a-table :columns="columns" :data-source="item" :pagination="page" size="middle" @change="more">
					<span slot="img" slot-scope="text">
						<img :src="text" style="max-width:50px;" />
					</span>	
          <span slot="action" slot-scope="text, record">
            <a @click="handleEdit(record)">编辑</a>
            <a-divider type="vertical" v-if="record.id>1"/>
            <a-popconfirm title="确认删除？" @confirm="del(record)" v-if="record.id>1">
              <a-icon slot="icon" type="question-circle-o" style="color: red"/>
              <a>删除</a>
            </a-popconfirm>
          </span>
        </a-table>
      </div>
    </div>

    <!-- 提交表单 -->
    <create-form ref="createModal" @ok="handleOk" />
  </div>
</template>

<script>
import CreateForm from "./modules/CreateForm";
import { admin_index, admin_add, admin_del } from "../../../api/index.js";

export default {
  components: {
    CreateForm,
  },
  methods: {
    async more(e) {
      let res = await admin_index({ page: e.current });
      if (res.code == 200) {
        this.item = res.data.item.data;
        this.page.total = res.data.item.total;
        this.page.current = res.data.item.current_page;
				
				this.$store.commit('set_auth',res.data.auth)
      }
    },
    handleEdit(record) {
      this.id = record.id;
      this.$refs.createModal.init(record);
    },
    handleOk(e) {
			admin_add({id:this.id,item:e}).then((res)=>{
				if(res.code==200){
					this.$message.success(res.msg);
					this.$refs.createModal.cancel();
					this.more({ current: this.page.current })
				}else{
					this.$message.error(res.msg);
				}
			})   
    },
    del(e) {
			admin_del({id:e.id}).then((res)=>{
				if(res.code==200){
					this.$message.success(res.msg);
					this.more({ current: this.page.current })
				}else{
					this.$message.error(res.msg);
				}
			})
	}
  },
  mounted() {
    this.more({ current: 1 });
  },
  data() {
    return {
      name: "",
      id: 0,
      queryParam: {},
      page: { pageSize: "10", total: 0, current: 0 },
      columns: [
        { key: "id", title: "编号", dataIndex: "id", width: 80 },
        { title: "登录账号", dataIndex: "username"},
				{ title: "账号名称", dataIndex: "name", width: 120  },
        { title: "更新时间", dataIndex: "update_time", width:220 },
        {
          title: "操作",
          key: "action",
          width: 100,
          scopedSlots: { customRender: "action" },
        },
      ],
      item: [],
    };
  },
  props: {
    msg: String,
  },
  watch: {
    $route: {
      handler: function (route) {
        this.name = route.name;
      },
      immediate: true,
    },
  },
};
</script>

<stype>
	
</style>